import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { createStoreMapping } from "../../controllers/createStoreMapping";

export const App = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [provider, setProvider] = useState("");
  const [providerID, setProviderID] = useState("");
  const [hhID, setHHID] = useState("");

  const inputEl = useRef(null);
  const selectorEl = useRef(null);

  const formSubmit = async (e: any) => {
    e.preventDefault();

    const providerIDValue = (inputEl.current as any)?.value || "";
    const providerValue = (selectorEl.current as any)?.value || "";
    if (!providerIDValue || !providerValue) {
      props.showModal(`<p>Provider ID cannot be empty.</p>`);
    } else {
      setProvider(providerValue);
      setProviderID(providerIDValue);
      setHHID(uuidv4());
    }
  };

  const signOut = (e: any) => {
    e.preventDefault();

    props.loginHandler(false);
  };

  useEffect(() => {
    if (!provider || !hhID || !providerID) {
      return;
    }

    const successCreateHandler = (hhID: string) => {
      setProviderID("");

      setLoading(false);
      props.showModal(
        `<p>Enter the following value into the backend:</p><p>${hhID}</p>`
      );
    };

    const failCreateHandler = (error: string) => {
      setHHID("");

      setLoading(false);
      props.showModal(`<p>Failed to create a store:</p><p>${error}</p>`);
    };

    // reset
    setLoading(true);

    createStoreMapping(hhID, providerID, provider)
      .then((result) => {
        if (result.success) successCreateHandler(result.message);
        else failCreateHandler(result.message);
      })
      .catch((e: any) => {
        failCreateHandler(e.message);
      });
  }, [provider, hhID, providerID, props]);

  return (
    <div className="App">
      <div className="formContainer">
        <form onSubmit={formSubmit}>
          <a className="link" href="/" onClick={signOut}>
            Sign out
          </a>

          <img className="logo" src={props.logo} alt="Logo" />

          <div className="floatinput">
            <label className="active">
              Provider <span className="warning">*</span>
            </label>
            <select
              ref={selectorEl}
              name="provider"
              data-testid="provider-selector"
            >
              <option value="doshii">Doshii</option>
            </select>
          </div>
          <div className="floatinput">
            <label className="active">
              Provider ID <span className="warning">*</span>
            </label>
            <input
              ref={inputEl}
              type="text"
              name="providerID"
              placeholder="12345"
              data-testid="provider-id-input"
            />
          </div>

          {loading ? (
            <p className="createStoreMapLoader">
              Creating Store map... Please wait
            </p>
          ) : (
            <input
              className="button"
              type="submit"
              value="Submit"
              data-testid="provider-submit"
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default App;

import axios, { AxiosRequestConfig } from "axios";
import { loadItem } from "./utils/auth";

export const createStoreMapping = async (
  hhID: string,
  providerID: string,
  provider: string
): Promise<{
  success: boolean;
  message: string;
}> => {
  try {
    console.log("Retrieving auth token");
    const { email, password, token } = loadItem("auth");

    console.log(`Requesting mapping for providerID ${providerID}`);
    const providerData = JSON.stringify({
      email: `${email}@hungryhungry.com`,
      password,
      hhID,
      providerID,
      provider,
      token,
    });

    const { REACT_APP_BASE_URL } = process.env;

    const config: AxiosRequestConfig = {
      method: "post",
      url: `${REACT_APP_BASE_URL}/createMapping`,
      data: providerData,
      headers: {},
    };

    console.log(
      `Making call to Onboarding lambda to create mapping for ${providerID}`
    );
    const { data: responseData } = await axios.request(config);

    if (!responseData?.success) {
      throw new Error(`Failed to map ${providerID} with provider ${provider}`);
    }

    console.log(`Successfully mapped ${providerID} with provider ${provider}`);
    return {
      success: true,
      message: responseData?.message,
    };
  } catch (e: any) {
    console.error("Something went wrong in createStoreMapping", e.message);
    throw e;
  }
};

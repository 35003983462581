import { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { Login } from "../Login";
import { Main } from "../Main";
import HH_logo from "../../assets/logo.png";

import { removeItem } from "../../controllers/utils/auth";

import "../Main/Main.scss";

const alertRoot = document.getElementById("alert-root");

const AlertComponent = (props: any) => {
  const { onConfirm, message } = props;

  const createMarkup = (message: string) => {
    return {
      __html: message,
    };
  };

  return (
    <>
      <div className="backdrop"></div>
      <div className="message-box">
        <div className="message">
          <div
            className="messageDescription"
            dangerouslySetInnerHTML={createMarkup(message)}
          ></div>

          <button className="continueButton" onClick={onConfirm}>
            Continue
          </button>
        </div>
      </div>
    </>
  );
};

const App = () => {
  const [alertState, toggleAlertState] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loggedIn, setLoginState] = useState(false);

  const alertComponentDiv = document.createElement("div");
  alertComponentDiv.className = "alert-modal-wrapper";

  const closeModal = () => {
    toggleAlertState(false);
    setAlertMessage("");
  };

  const showModal = (message: string) => {
    toggleAlertState(true);
    setAlertMessage(message);
  };

  const additionalStyle = {
    backgroundImage: "url(assets/background.png)",
  };

  const signOut = useCallback(() => {
    if (loggedIn) {
      removeItem("auth");
      setLoginState(false);
    }
  }, [loggedIn]);

  useEffect(() => {
    if (alertRoot) {
      alertRoot.innerHTML = "";

      if (alertState) {
        alertRoot.appendChild(alertComponentDiv);
      }
    }
  }, [alertState, alertMessage, alertComponentDiv]);

  return (
    <>
      <div className="background" style={additionalStyle}></div>
      {ReactDOM.createPortal(
        <AlertComponent
          show={alertState}
          onConfirm={closeModal}
          message={alertMessage}
        />,
        alertComponentDiv
      )}

      {!loggedIn ? (
        <Login
          logo={HH_logo}
          loginHandler={setLoginState}
          showModal={showModal}
        />
      ) : (
        <Main logo={HH_logo} showModal={showModal} loginHandler={signOut} />
      )}
    </>
  );
};

export default App;

import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { loadItem, saveItem } from "../../controllers/utils/auth";

import "./Login.scss";

const Login = (props: any) => {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);

  const formSubmit = (e: any) => {
    e.preventDefault();

    const emailValue: string = (emailRef.current as any).value;
    const passwordValue: string = (passwordRef.current as any).value;

    if (!emailValue || !passwordValue) {
      props.showModal(`<p>Username and Password cannot be empty</p>`);
      return;
    }

    setEmail(emailValue);
    setPassword(passwordValue);
  };

  useEffect(() => {
    const { REACT_APP_BASE_URL } = process.env;

    const authenticate = async (creds: any) => {
      setLoading(true);

      try {
        console.log("Beginning log in request");
        const { data: authResponse } = await axios.post(
          `${REACT_APP_BASE_URL}/auth`,
          creds,
          {}
        );

        if (authResponse?.token) {
          console.log("successful response");
          saveItem(
            "auth",
            {
              email,
              password,
              token: authResponse.token,
            },
            7
          );
          props.loginHandler(true);
          return true;
        }

        if (authResponse?.error) {
          props.showModal(`<p>${authResponse?.error}</p>`);
          setPassword("");
        } else {
          props.showModal(
            `<p>Ooops! Something went wrong. Please try again later</p>`
          );
        }

        setLoading(false);
        return false;
      } catch (e) {
        setLoading(false);
        props.showModal(
          `<p>Ooops! Something went wrong. Please try again later</p>`
        );
        return false;
      }
    };

    if (email && password) {
      authenticate({ email: `${email}@hungryhungry.com`, password });
    }
  }, [email, password, props]);

  useEffect(() => {
    // yep load the auth item
    const authItem = loadItem("auth");
    if (authItem) {
      saveItem("auth", authItem, 7);
      props.loginHandler(true);
    }
  }, [props]);

  return (
    <div className="Login">
      <div className="formContainer">
        <form onSubmit={formSubmit}>
          <img className="logo" src={props.logo} alt="logo" />

          <div className="floatinput">
            <label className="active">
              Username <span className="warning">*</span>
            </label>
            <div className="inputContainer username">
              <input
                ref={emailRef}
                type="text"
                placeholder="Username"
                data-testid="email-input"
              />

              <p className="inputDomainLabel">@hungryhungry.com</p>
            </div>
          </div>

          <div className="floatinput">
            <label className="active">
              Password <span className="warning">*</span>
            </label>
            <input
              ref={passwordRef}
              type="password"
              placeholder="Password"
              data-testid="password-input"
            />
          </div>

          {loading ? (
            <p className="createStoreMapLoader">Signing in... Please wait</p>
          ) : (
            <input
              className="button"
              type="submit"
              value="Sign In"
              data-testid="sign-in-input"
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;

export const loadItem = (key: string): any => {
  try {
    const result: string | null = localStorage.getItem(key);
    if (!result) {
      return false;
    }

    const now = new Date().valueOf();
    const parsedResult = JSON.parse(result);
    if (parsedResult?.expiry <= now) {
      return false;
    }

    return parsedResult.value;
  } catch (e: any) {
    return false;
  }
};

export const saveItem = (key: string, value: any, expiry: number = 7) => {
  const dateExpiry = new Date().valueOf() + expiry * 86400000;
  localStorage.setItem(
    key,
    JSON.stringify({
      value,
      expiry: dateExpiry,
    })
  );
};

export const removeItem = (key: string) => {
  localStorage.removeItem(key);
};
